<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-info">
						<div class="personal-info-item flex">
							<div class="personal-info-title">设置头像</div>
							<el-upload class="avatar-uploader" :action="baseURL+'/module/attachment/add'"
								:show-file-list="false" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload" :headers="headers">
								<img v-if="postData.avatar" :src="postData.avatar" class="avatar">
								<div :src="userInfo.avatar" v-else class="el-icon-plus avatar-uploader-icon"></div>
							</el-upload>
						</div>
						<div class="personal-info-item1">
							<div class="personal-info-title flex">
								<div>用户名称</div>
								<div style="margin-left:5px;color:#fd2323 ;">*</div>
							</div>
							<input class="personal-info-intext" placeholder="在这输入您的用户名" v-model="postData.nickname" />
						</div>
						<div class="personal-info-item1">
							<div class="personal-info-title flex">
								<div>真实姓名</div>
							</div>
							<input class="personal-info-intext" placeholder="在这输入您的真实姓名" v-model="postData.name"/>
						</div>
						<div class="personal-info-item1">
							<div class="personal-info-title flex">
								<div>公司名称</div>
							</div>
							<input class="personal-info-intext" placeholder="在这输入您的公司名称" v-model="postData.company"/>
						</div>
						<div class="personal-info-bigbtn jump" @click="save()">确定</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import store from '@/store'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Header
		},
		data() {
			return {
				baseURL: process.env.VUE_APP_BASE_API,
				menuPic:'',
				sortIndex: 0,
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
				imageUrl: '',
				headers:{
					token:store.state.userToken,
				},
				postData:{
					avatar:'',
					nickname:'',
					name:'',
					company:'',
				}
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			this.postData.nickname=this.userInfo.nickname;
			this.postData.name=this.userInfo.name || '兰境用户';
			this.postData.company=this.userInfo.company;
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
		},
		methods: {
			sortSwitch(index) {
				this.sortIndex = index;
				this.$router.push({
					path: this.nav[index].href
				});
			},
			handleAvatarSuccess(res, file) {
				this.postData.avatar = file.response.data.url;
				console.log(file);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
			//保存信息
			save(){
				if(this.postData.nickname.length<1){
					alert('请填写用户名');
					return;
				}
				console.log(this.postData)
				this.$api.editMember(this.postData).then(res=>{
					console.log(res,'修改返回');
					// 获取用户信息
					this.$api.getUserInfo().then(userRes => {
						console.log(userRes, '用户信息');
						this.$store.commit('setUserInfo', userRes.data);
						alert('修改成功');
					});
				})
			}
		},
	};
</script>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 0;
		margin-left: 237px;
		width: 84px;
		height: 84px;
		border-radius: 50%;
		background-image: url(../../public/images/head_01.png);
		background-size: 100% 100%;
	}

	.avatar {
		margin-left: 237px;
		width: 84px;
		height: 84px;
		border-radius: 50%;
		display: block;
	}
</style>
